<template>
  <div class="auth-wrapper auth-v1 px-2" style="min-height: 100vh">
    <div class="auth-inner py-2" style="max-width: 1000px">
      <b-card class="mb-0">
        <!-- prettier-ignore -->
        <pre style="background-color: transparent !important; white-space: break-spaces; margin-bottom: 0">Ecoplot利用規約

第１章　総則
第１条 　（規約）
本規約は、株式会社ブルーアースセキュリティ（以下「当社」といいます。）が提供するEcoplot（以下「本サービス」といいます。）の利用に関し、当社と登録利用者の間に適用されます。

第２条 　（定義）
本規約において、次の各号に掲げる用語の意味は、当該各号に定めるとおりとします。

（1） 登録利用者

本規約に同意の上、当社と本サービスの利用に関する契約（以下「本利用契約」といいます。）を締結した法人、団体、組合または個人をいいます。

（2） 運用管理者

本サービスの申込み時に登録利用者が指定した、本サービスの利用に関する管理者権限を有し、ユーザーの指定を行う登録利用者に所属する関係者（登録利用者が個人である場合は、当該個人）をいいます。

（3） ユーザー

運用管理者により指定され、本サービスへのアクセスおよび使用が許可されている者をいいます。運用管理者は、当社が別途定める人数の範囲でユーザーを指定することができます。

（4） 利用者

運用管理者及びユーザーをいいます。

（5） 協力会社

本サービスの開発及び運用に係わる会社、法人その他の事業体をいう。

（6）本サイト

当社が本サービスを提供するために運営するウェブサイトをいいます。本サービスは、本サイトまたは次号に定義する本ソフトウェアを介して登録利用者および利用者に提供されます。

（7）本ソフトウェア

当社が本サービスを提供するために作成・公開するアプリケーション・ソフトウェアをいい、アップデート版、修正版、代替品および複製物を含みます。

（8）登録情報

本サービスを利用する前提として登録することが求められる、当社が定める登録利用者に関する情報をいいます。

（9）登録利用者情報

本サービスのために当社が管理するサーバーに保存された各種情報や通信記録その他の登録利用者および利用者の一切の情報をいい、登録情報および本サービスを通じて当社が提供し、または登録利用者が取得した情報を含みます。

（10）有料サービス

本サービスのうち、有料にて提供されるサービスをいいます。

（11）有料登録利用者

有料サービスの利用を選択した登録利用者をいいます。

第３条 　（規約の変更）
当社は、当社が必要と判断したときは、本規約を変更することができるものとします。

２　当社が本規約の内容を変更するときは、当該変更の事実、その効力発生日および変更内容を本規約の定めに従い登録利用者に通知または公表します。当該通知または公表後、登録利用者および利用者が本サービスを利用した場合または当社の定める登録抹消の手続をとらなかった場合には、登録利用者は、本規約の変更に同意したものとみなします。

第４条 　（通知・公表）
当社は、本サービスに関連して登録利用者に通知または公表をする場合には、本サイトに掲示する方法または登録情報として登録された電子メールアドレス・住所に宛てて電子メール・文書を送信する方法など、当社が適当と判断する方法で実施します。

2　本サービスに関する問い合わせその他登録利用者から当社に対する連絡または通知は、当社の定める方法で行うものとします。

第２章　契約の成立
第５条 　（本利用契約の成立（入会））
本利用契約は、本サービスの利用を希望する者が、登録情報を登録した上で、当社が指定する方法で申込みを行い、これを当社が受諾した時点で成立するものとします。本サービスの利用を希望する者が当社が指定する方法で申込みを行った時点で、当該申込者は本規約に同意したものとみなされます。

２　登録利用者は、当社が前項の申込みを受諾した日（以下「契約成立日」といいます。）から本サービスを利用できます。

３　未成年者が本サービスの利用を希望する場合には、法定代理人の同意が必要になります。未成年者から第1項の申込みがあったときは、本サービスの利用および本規約の内容について、法定代理人の同意があったものとみなします。

第６条 　（利用料金および支払方法）
有料登録利用者は、当社に対して、有料サービスの利用の対価として、当社が当該有料サービスごとに別途定める利用料金を支払います。

２　有料登録利用者は、利用料金を、当社所定の決済手段により、当社指定の期日までに支払うものとし、当社は、いかなる場合にも受領した利用料金の返金には応じません。利 用料金の支払いを遅滞した場合、有料登録利用者は、当社に対し、年１４．６％の割合による遅延損害金を支払うものとします。

３　本サービスを利用するために必要となる通信費（本ソフトウェアのダウンロードおよび利用のために発生する通信費を含みます。）、および通信機器等は、登録利用者の負担と責任により準備するものとします。但し、登録利用者の使用する通信機器等において、本サイトおよび本ソフトウェアが正常に動作することを保証するものではありません。

第３章　サービス利用上の注意事項
第７条 （登録利用者ID等）
登録利用者は、本サービス申込みの際に指定した登録利用者ID（メールアドレス）および当該登録利用者IDにかかるパスワード（以下併せて「登録利用者ID等」といいます。）を自己の責任において厳重に管理するものとし、これらを用いてなされた一切の行為についてその責任を負います。

２　登録利用者は、自己の責任において、利用者ごとに指定されたユーザーID（当社がユーザーごとに割り当てる識別子で、当該ユーザーが本サービスを利用するために用いるものをいいます。以下同じ。）および当該ユーザーIDにかかるパスワード（以下併せて「ユーザーID等」といいます。）を厳重に管理し、またユーザーをして厳重に管理させるものとし、これらを用いてなされた一切の行為についてその責任を負います。

３　登録利用者は、利用者以外の者に本サービスを利用させてはいけません。

４　登録利用者は、利用者に本規約の内容を遵守させるものとします。利用者の本規約違反は、登録利用者の本規約違反とみなし、登録利用者および利用者は連帯して責任を負うものとします。

第８条 （登録情報の変更）
登録利用者は、登録情報に変更が生じた場合には、当社が指定する方法により速やかに届出を行います。当社は、登録情報の変更の届出がなされなかったことにより登録利用者に生じた損害について一切の責任を負いません。

第９条 （バックアップ）
登録利用者は、登録利用者情報の全てについて、自己の責任において記録し、保存・管理します。

２　登録利用者は、登録利用者情報について、自己の責任においてバックアップ作業（当社が提供する本サービスの機能を利用する場合を含みますが、それに限りません。）を行うものとし、当社は、バックアップデータが存在しないこと、または登録利用者がバックアップ作業を適切に実施しなかったこと等により発生した登録利用者の損害および不利益につき、一切の責任を負いません。

３　当社は、登録利用者情報をバックアップとして記録することがあります。但し、前項に定める登録利用者の責任において行うバックアップを補完するものではなく、登録利用者情報の復旧を保証するものではありません。

４　有料登録利用者以外の登録利用者は、当社が保有する登録利用者情報の一部が一定期間をもって自動的に消去される場合があることを予め了承します。

第１０条 （禁止行為）
登録利用者は、本サービスに関連して次の各号に定める行為を行ってはいけません。

（1） 当社に対して虚偽の申告をする行為

（2） 本利用契約に基づき当社から提供された本サイトおよび本ソフトウェアを含む情報および役務を本サービスの利用以外の目的のために使用する行為

（3） 当社もしくは第三者の財産（知的財産権を含みます。）、プライバシー、名誉、信用、肖像、パブリシティその他の権利・利益を侵害する行為または侵害するおそれのある行為

（4） 前号以外で当社もしくは第三者の権利・利益を侵害する行為または侵害するおそれのある行為

（5） 法令に違反し、もしくは公序良俗に反する行為またはそのおそれのある行為

（6） 第三者の登録利用者ID等、ユーザーID等を不正に使用または取得する行為その他登録利用者または利用者が、第三者を装って本サービスを利用する行為

（7） コンピュータウィルスなどの有害なプログラムを使用し、もしくは送信する行為、またはそのおそれのある行為

（8）不正アクセス行為等当社による業務の遂行、本サービスの実施もしくは当社の通信設備等に支障を及ぼし、またはそのおそれがあると当社が判断する行為

（9） 本サービスのネットワークまたはシステム等に過度な負荷をかける行為

（10）本サービスの他の利用者の情報の収取

（11）前項各号の行為を直接または間接に惹起し、または容易にする行為

（12）その他当社が不適切と判断する行為

第４章　解約・解除・退会
第１１条 （登録利用者による本サービスの解約）
登録利用者は、本利用契約を解約する場合、当社所定の方法により解約手続きを行うこととし、当該解約手続きの完了をもって、本利用契約が解約されるものとします。この場合、登録利用者は自己の責任において、当社からの解約に関する通知を確認するものとします。

２　登録利用者が第１項により本利用契約を解約した場合、当社は登録利用者情報を消去することができます。

第１２条 （当社による契約解除）
当社は、登録利用者が次の各号の一つに該当した場合には、登録利用者に対して何らの通知催告をすることなく、本利用契約の一部または全部を解除して登録利用者に対する退会処分を行い、または本サービスの提供を停止することができます。

（1） 本規約に違反する行為を行った場合

（2） 当社に提供された登録情報の全部または一部につき虚偽、誤記または記載漏れがあった場合

（3） 現に制限行為能力者であるか、または制限行為能力者になった場合において、催告後相当期間を経過しても法定代理人の記名押印のある同意書または追認書の提出がない場合

（4） 反社会的勢力等（暴力団、暴力団員、右翼団体、反社会的勢力、暴力団準構成員、総会屋、社会運動等標榜ゴロ、特殊知能暴力集団その他これに準ずる者を意味します。以下同じ。）である、資金提供その他を通じて反社会的勢力等の維持、運営もしくは経営に協力もしくは関与する等反社会的勢力等との何らかの交流もしくは関与を行っている、または反社会的勢力等が経営に実質的に関与している法人等であると当社が合理的に判断した場合

（5） 支払停止もしくは支払不能となった場合、仮差押、差押、競売、破産手続開始、会社更生手続開始、民事再生手続開始等の申立があった場合、または公租公課等の滞納処分を受けた場合

（6） 過去に本サービスについて退会処分を受けたことが判明した場合

（7） 登録利用者および運用管理者が90日以上にわたって所在不明または連絡不能となった場合

（8） その他、当社が登録利用者として不適当であると合理的に判断した場合

２　前項に基づき登録利用者が退会処分を受けた場合でも、当社は、登録利用者および第三者に生じた損害および不利益につき一切の責任を負いません。

３　第１項に基づき登録利用者が退会処分を受けた場合、当社は登録利用者情報を消去することができます。

第５章　サービスの停止、変更および終了
第１３条 （サービスの停止）
当社は、次の各号のいずれかの事由が生じた場合には、事前に登録利用者に通知をすることなく、本サービスの一部または全部を停止することができます。

（1） 本サービス提供にあたり必要なシステム、設備等に障害が発生し、またはメンテナンス、保守もしくは工事等が必要となった場合

（2） 電気通信事業者が電気通信サービスの提供を中止するなど、当社以外の第三者の行為に起因して、本サービスの提供を行うことが困難になった場合

（3） 非常事態（天災、戦争、テロ、暴動、騒乱、官の処分、労働争議等）の発生により、本サービスの提供が困難になった場合、または困難になる可能性のある場合

（4） 法令規制、行政命令等により、本サービスの提供が困難になった場合

（5） その他、当社の責めに帰することができない事由により、当社が必要やむを得ないと判断した場合

２　当社は、前項に基づいて本サービスを停止したことにより登録利用者および第三者に生じた損害および不利益につき一切の責任を負いません。

第１４条 （サービスの変更）
当社は、当社の裁量により本サービスの一部の内容を追加または変更することができます。当社は、本条に基づく本サービスの追加または変更により、変更前の本サービスのすべての機能・性能が維持されることを保証するものではありません。

２　当社は、前項に基づいて本サービスを追加または変更したことにより登録利用者に生じた損害および不利益につき一切の責任を負いません。

第１５条 （サービスの中止および終了）
当社は、事前に登録利用者に通知をしたうえで、当社の裁量により本サービスの一部もしくは全部の提供を中止または終了することができます。但し、中止または終了の内容が重大でない場合には、通知をすることなくこれらを実施することができます。

２　当社は、前項に基づいて本サービスを中止または終了したことにより登録利用者に損害が発生した場合でも、一切の責任を負いません。

第６章　本ソフトウェアの提供
第１６条（使用許諾）
当社は、本サービスの利用に際して本ソフトウェアをダウンロードした利用者に対し、本規約に従うことを条件に、本ソフトウェアの非独占的な使用を許諾します。登録利用者は、利用者をして、本ソフトウェアの使用に関し、本章に定める事項を遵守せしめるものとします。

第１７条（ソフトウェアに関する禁止事項）
登録利用者は、本ソフトウェアの利用にあたり、第１２条に定める事項の他、次の各号に定める行為を行ってはなりません。

（1）本ソフトウェアの複製、翻訳、翻案等の改変を行うこと

（2）本ソフトウェアの販売、配布、再使用許諾、公衆送信（送信可能化を含みます。）、貸与、譲渡、またはリースその他の処分を行うこと

（3）本ソフトウェアに設けられたコピーガード等の技術的な保護手段を回避する方法で使用すること

（4）本ソフトウェアの一部または全部のリバースエンジニアリング、逆コンパイルもしくは逆アセンブルを行い、またはその他の方法でソースコードを抽出すること

（5）第三者が複製できるように本ソフトウェアを公開すること

（6）前各号に定める他、本ソフトウェアの利用目的に照らして当社が不適切と判断する行為

第１８条（利用制限）
次の各号に定める場合、登録利用者による本ソフトウェアの利用の一部または全部が制限されることがあります。

（1）利用資格等の確認を目的としたライセンス認証、登録利用者ID等の認証機能において、利用資格等の確認ができない場合。

（2）インターネット接続ができない場所において本ソフトウェアを利用する場合。

（3）リアルタイム通信ができない通信状況において本ソフトウェアを利用する場合。

２　当社は、本ソフトウェアに関するサポート、修正版（アップデート版を含みます。）の提供を行う義務を負いません。またあらかじめ登録利用者へ通知を行うことなく、本ソフトウェアの修正、変更、アップデート、または提供の終了を行う場合があります。

第７章　一般条項
第１９条 （無保証） 
当社は、本サービスが推奨環境において機能するように合理的な最大限の努力を行います。但し、当社は、本サービスが登録利用者の特定の目的に適合すること、本サービスが登録利用者の期待する正確性、正当性、有用性、完全性等を有すること、登録利用者による本サービスの利用が登録利用者に適用のある法令または業界団体の内部規則等に適合すること、及び不具合が生じないことを保証するものではありません。

第２０条 （知的財産権）
本サービスに関する著作権、著作者人格権、特許権、実用新案権、意匠権、商標権およびパブリシティ権等の一切の知的財産権は当社および正当な権利者たる第三者に帰属し、本利用契約の成立は、本サービスの利用に必要な範囲を超える知的財産権の利用許諾を意味するものではありません。

２　登録利用者および利用者が本サービス上において投稿等を行った場合、登録利用者および利用者は、著作物性の有無を問わず、投稿等の内容の一部又は全部に関し、発生し得る全ての著作権（著作権法第２７ 条及び第２８条に定める権利を含みます。）について、目的を問わず、無償かつ無制限に利用できる権利を当社に対して許諾することについて同意します。

３　登録利用者および利用者は、当社および当社から権利を承継し、または許諾された者に対して著作者人格権を行使しないことに同意するものとします。

４　登録利用者および利用者は、第２項の投稿等について、自らが投稿その他送信することについての適法な権利を有していることおよび投稿等の内容が第三者の権利を侵害していないことについて、当社に対し表明し、保証するものとします。

５　登録利用者および利用者が前項に違反して問題が発生した場合、登録利用者および利用者は、自己の費用と責任において当該問題を解決するとともに、当社に何らの不利益、負担または損害を与えないよう適切な措置を講じなければなりません。

第２１条 （損害賠償及び免責） 
１　当社は、本サービスに関して当社の帰責事由により登録利用者に損害を生じさせた場合、次の各号に定める範囲でその損害を賠償する責任を負います。

（1）当社の故意または重過失による場合：当該損害の全額

（2）当社の軽過失による場合：現実かつ直接に発生した通常の損害（特別損害、逸失利益、間接損害および弁護士費用を除く。）の範囲内とし、かつ、金１万円（ただし、有料登録利用者の場合は損害発生日から直近半年間の利用料金の累積総額とします。）を上限とします。 

２　前項にかかわらず、登録利用者が法人である場合または個人が事業としてもしくは事業のために本サービスを利用する場合には、本サービスに関連して登録利用者が被った損害につき当社は一切の責任を負いません。

３　登録利用者は、本規約に違反することにより、または本サービスの利用に関して当社に損害を与えた場合、当社に対し当社に生じた損害を賠償しなければなりません。

４　本サービスに関して登録利用者と第三者との間に紛争が生じた場合、登録利用者は自己の責任と費用で解決するものとし、当社に何ら迷惑をかけず、またこれにより当社が被った損害（弁護士費用を含みます。）を補償します。

第２２条 （委託）
当社は、本サービスに関する業務の一部または全部を第三者に委託することができるものとします（以下その場合の当該第三者を「下請業者」といいます。）。

第２３条 （情報管理）
当社は、登録利用者情報について、登録利用者の事前の同意を得ずに第三者に開示しません。但し、次の各号の場合はこの限りではありません。

（1） 法令または公的機関からの要請を受け、要請に応じる必要を認めた場合

（2） 人の生命、身体または財産の保護のために必要があり、かつ登録利用者の同意を得ることが困難である場合

（3） 本サービスの提供、機能向上または改善のために必要な受託者もしくは代理人等に情報を開示する場合

（4） 当社および協力会社間で連携したサービスを提供するために共同利用する場合

２　前項にかかわらず、当社は、登録利用者情報の属性集計・分析を行い、登録利用者が識別・特定できないように加工したもの（以下「統計資料」といいます。）を作成し、本サービスおよび当社のその他のサービスのために利用することがあります。また、統計資料を第三者に開示することがあります。

３　当社は、登録利用者情報の紛失、破壊、改鼠、漏洩等の危険に対して、合理的な最大限の安全対策を講じます。

４　当社は、電話応対品質向上等のため、登録利用者との間の電話応対を録音し、録音内容を業務において使用することができるものとします。

第２４条 （個人情報）
当社の個人情報の取り扱いについてはプライバシーポリシーに定めるとおりとします。

２　当社は、本利用契約の終了後も、プライバシーポリシー記載の利用目的の範囲内で登録利用者および利用者の個人情報を利用できるものとします。

第２５条 （権利義務の譲渡禁止）
登録利用者は、当社の事前の書面による承諾を得ることなく、本利用契約に基づく権利義務を第三者に譲渡（合併、会社分割等による包括承継も含みます。）しまたは担保の目的に供してはならないものとします。

第２６条 （準拠法、管轄裁判所）
本規約および本利用契約は、日本法によって解釈され、日本法を準拠法とします。

２　本利用契約に関して登録利用者と当社の間に紛争が生じた場合には、東京地方裁判所を第一審の専属的合意管轄裁判所とします。

第２７条 （本規約の適用制限）
本規約の一部の条項が法令に違反するとされた場合、当該条項は、その限りにおいて本利用契約に適用されないものとし、本規約の他の条項は引き続き有効に存続するものとします。

附則

令和６年５月２１日　制定・施行</pre>
        <hr />
        <div class="text-center">
          <b-button variant="flat-secondary" class="mr-1" @click="denyPolicy">同意しません</b-button>
          <b-button variant="success" @click="acceptPolicy">同意します</b-button>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BButton, BForm, BFormInput, BFormGroup, BCard, BLink, BCardTitle, BCardText, BInputGroup, BInputGroupAppend, BImg } from 'bootstrap-vue'
// import Logo from '@/@core/layouts/components/Logo.vue'
import { required, email } from '@/@core/utils/validations/validations.js'
import { togglePasswordVisibility } from '@/@core/mixins/ui/forms'
import FacebookIcon from '/static/images/login/facebook.svg'
import GoogleIcon from '/static/images/login/google.svg'
import { ECOPLOT_API } from '@/constants/urls'
const axios = require('axios')

export default {
  components: { BButton, BForm, BFormInput, BFormGroup, BCard, BCardTitle, BLink, BCardText, BInputGroup, BInputGroupAppend, BImg, ValidationProvider, ValidationObserver, FacebookIcon, GoogleIcon },
  methods: {
    async acceptPolicy() {
      let token = localStorage.getItem('django-authentication-token')

      if (!token) return

      try {
        await axios.put(`${ECOPLOT_API}/users/current/`, { accept_pro_policy: true }, { headers: { Authorization: `Token ${token}` } })
        this.$store.dispatch('ecoplot/GET_USER')
      } catch (error) {
        this.$store.dispatch('ecoplot/LOGOUT')
      }
    },
    denyPolicy() {
      this.$store.dispatch('ecoplot/LOGOUT')
    },
  },
}
</script>

<style lang="scss">
@import '@/@core/scss/vue/pages/page-auth.scss';
</style>
